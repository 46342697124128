const colors = {
  Black : {
    bg:'#000',
  },
  White : {
    bg:'#ffffff',
  },
  Cream : {
    bg:'#fffff0',
  },
  Neon : {
    bg:'#ff00ff',
  }
};

export default colors;